import React from "react";
import CableTelSection from "../../components/Projects/Cabletel/cabletel.js";
import Layout from "../../components/UI/layout";
import SEO from "../../components/UI/seo";
import { graphql } from "gatsby";

const cableTelPage = () => (    
    <Layout>
        <SEO title="Codeicus | Cabletel" />
        <CableTelSection/>
    </Layout>
)

export default cableTelPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;